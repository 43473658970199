import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {headersAccept, headersAuthorization, headersContent} from "./APIToken";
import {BaseQueryArg} from "@reduxjs/toolkit/dist/query/baseQueryTypes";


export const tradersUserApi = createApi({
    reducerPath: 'tradersUserApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_URL}/user/forex/`,
        prepareHeaders: (headers, {getState}) => {
            headers.set("Content-type", headersContent)
            headers.set("Accept", headersAccept)
            headers.set("Authorization", headersAuthorization)
        },
    }),
    tagTypes: ['Trader', 'GRID'],
    endpoints: (build) => ({
        getAllUserTraders: build.query({
            query: (page) => ({
                url: '/traders',
                params: {
                    page,
                }
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Trader'],
        }),
        getHistory: build.query({
            query: ({id, page}) => ({
                url: `${process.env.REACT_APP_URL}/user/trading/${id}/history`,
                params: {
                    page,
                }
            }),
            keepUnusedDataFor: 0,
        }),
        getOpenPositions: build.query({
            query: ({id, page}) => ({
                url: `${process.env.REACT_APP_URL}/user/trading/${id}/opened`,
                params: {
                    page,
                }
            }),
            keepUnusedDataFor: 0,
        }),
        getTrader: build.query({
            query: (id) => ({
                url: `trader/${id}`
            }),
            keepUnusedDataFor: 0,
            providesTags: ['Trader']
        }),
        getTraderSymbol: build.query({
            query: (id) => ({
                url: `/trader/${id}/used-symbols`,
            }),
            keepUnusedDataFor: 0,
        }),
        addTrader: build.mutation({
            query: (body) => ({
                url: `/server`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Trader']
        }),
        subscribeTrader: build.mutation({
            query: ({idTrader, idAccount, body}) => ({
                url: `trader/${idTrader}/account/${idAccount}/subscribe`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Trader']
        }),
        updateSettingsTrader: build.mutation({
            query: ({idTrader, idAccount, body}) => ({
                url: `subscribe/${idTrader}/settings`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Trader']
        }),
        unsubscribeTrader: build.mutation({
            query: (idTrader) => ({
                url: `subscribe/${idTrader}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Trader']
        }),

        getCorrectData: build.query({
            query(id) {
                return {
                    url: `${process.env.REACT_APP_URL}/user/trading/account/${id}/lost/grids`
                }
            },
            providesTags: ['GRID']

        }),
        setCorrectData: build.mutation({
            query({id, idGrid, payload}) {
                return {
                    url: `${process.env.REACT_APP_URL}/user/trading/account/${id}/lost/grids/${idGrid}`,
                    method: 'POST',
                    body: payload,
                }
            },
            invalidatesTags: ['GRID']
        })
    })
});

export const {
    useGetAllUserTradersQuery,
    useSetCorrectDataMutation,
    useGetHistoryQuery,
    useGetTraderQuery,
    useGetOpenPositionsQuery,
    useGetCorrectDataQuery,
    useGetTraderSymbolQuery,
    useSubscribeTraderMutation,
    useUpdateSettingsTraderMutation,
    useUnsubscribeTraderMutation
} = tradersUserApi;

export const {getAllUserTraders} = tradersUserApi.endpoints;
