import MainLayout from "../widgets/mainLayout";
import PersonalSetting from "../widgets/personalSetting/personalSetting";
import {ToastContainer} from "react-toastify";
import React from "react";

const Settings = () => {
    return (
        <MainLayout heading="Настройки" fullHeight={true}>
            {/*<ToastContainer*/}
            {/*    icon={false}*/}
            {/*    position='top-center'*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    style={{*/}
            {/*        minWidth: '300px',*/}
            {/*        width: 'fit-content',*/}
            {/*    }}*/}
            {/*    theme='light'*/}
            {/*/>*/}
            <PersonalSetting/>
        </MainLayout>
    );
};

export default Settings;
