import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useGetCorrectDataQuery, useSetCorrectDataMutation} from "../../../store/API/tradersUserApi";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import moment from "moment";
import {Box, Button, Divider, Modal, Stack, TextField} from "@mui/material";
import IconClose from "../../../shared/assets/images/icons/iconClose";

const CorrectionPage = () => {
    const {id} = useParams()

    const {data, isLoading, error} = useGetCorrectDataQuery(id, {
        skip: !id,
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const [setCorrectData, {isLoading: isLoadingMutation}] = useSetCorrectDataMutation()
    const [openModal, setOpenModal] = useState<any>(null)

    const handleCloseModal = () => setOpenModal(null)
    const handleOpenModal = (variant: 'robot' | 'manual', data: any) => setOpenModal({
        ...data,
        lot_old: data?.lot,
        estimated_tp_old: data?.estimated_tp,
        variant
    })

    const handleChangeGrid = () => {
        setCorrectData({
            id,
            idGrid: openModal.id,
            payload: {type: openModal?.variant === 'robot' ? 3 : -1, lot: openModal?.lot, tp: openModal?.estimated_tp}
        })
            .unwrap()
            .then((res) => {
                console.log(res)
                handleCloseModal()
            })
            .catch((e) => {
                console.log(e)
                toast.error(e?.data?.message || e?.data?.errors?.lot[0] || 'Ошибка')
            })
    }

    useEffect(() => {
        //@ts-ignore
        if (error && error.data && error?.data?.message) {
            //@ts-ignore
            toast.error(error?.data?.message || 'Ошибка')
        }
    }, [error])


    return (
        <div>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{
                                "@media (max-width: 960px)": {
                                    minWidth: "130px"
                                },
                            }}>Называние</TableCell>
                            <TableCell sx={{
                                "@media (max-width: 960px)": {
                                    minWidth: "130px"
                                },
                            }}>Код</TableCell>
                            <TableCell sx={{
                                "@media (max-width: 960px)": {
                                    minWidth: "130px"
                                },
                            }}>Объем</TableCell>
                            <TableCell sx={{
                                "@media (max-width: 960px)": {
                                    minWidth: "130px"
                                },
                            }}>Дата когда попала</TableCell>
                            <TableCell sx={{
                                "@media (max-width: 960px)": {
                                    minWidth: "130px"
                                },
                            }}>Цена</TableCell>
                            <TableCell sx={{
                                width: '200px',
                                "@media (max-width: 960px)": {
                                    minWidth: "130px"
                                },
                            }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data?.map((item: any) => (
                            <TableRow key={item?.id}>

                                <TableCell>{item?.symbol?.name ?? '-'}</TableCell>
                                <TableCell>{item?.symbol?.code ?? '-'}</TableCell>
                                <TableCell>{item?.lot || 0}</TableCell>
                                <TableCell>{item?.date ? moment(item?.date).format('DD.MM.YYYY HH:mm') : '-'}</TableCell>
                                <TableCell>{`${item?.price} ₽` || '-'}</TableCell>
                                <TableCell sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    justifyContent: 'center',
                                    paddingInline: '10px'
                                }}>
                                    <Button color='success' onClick={() => handleOpenModal('robot', item)}
                                            fullWidth>Робот</Button>
                                    <Button color='success' onClick={() => handleOpenModal('manual', item)}
                                            fullWidth>Ручная</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={Boolean(openModal)} onClose={handleCloseModal} sx={{
                "@media (max-width:980px)": {
                    maxWidth: '95%',
                    width: '100%',
                    margin: '0 auto',
                }
            }}>
                <Box sx={{maxWidth: 620}}>
                    <Stack onClick={handleCloseModal}
                           sx={{position: "absolute", top: 14, right: 28, cursor: "pointer"}}>
                        <IconClose/>
                    </Stack>
                    <Stack className="h2 white-90"
                           sx={{mb: 7}}>{`${openModal?.variant === 'robot' ? 'Робот' : 'Ручная'} (${openModal?.symbol?.name} ${openModal?.symbol?.code})`}</Stack>
                    <Divider variant="fullWidth" sx={{mb: 7}}/>
                    <Stack spacing={7}>
                        <Stack className="h2 white-100" spacing={14}>
                            <TextField
                                sx={{
                                    '& input[type=number]': {
                                        '-moz-appearance': 'textfield',
                                        '-webkit-appearance': 'none',
                                        appearance: 'textfield',
                                    },
                                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                        '-webkit-appearance': 'none',
                                        margin: 0,
                                    },
                                }}
                                required={true}
                                fullWidth
                                value={Math.abs(openModal?.lot) || ''}
                                onChange={(e) => setOpenModal({...openModal, lot: Math.abs(+e.target.value)})}
                                label={'Выберите количество лотов'}
                                error={+openModal?.lot > +openModal?.lot_old}
                                helperText={+openModal?.lot > +openModal?.lot_old ? `Макс. лотов ${openModal?.lot_old} шт.` : ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="number"
                                inputProps={{min: 0}}
                            />
                            {openModal?.variant === 'robot' && <TextField
                                // error={+openModal?.estimated_tp > +openModal?.estimated_tp_old}
                                // helperText={+openModal?.estimated_tp > +openModal?.estimated_tp_old ? `Макс. TP ${openModal?.estimated_tp_old}` : ''}
                                sx={{
                                    '& input[type=number]': {
                                        '-moz-appearance': 'textfield',
                                        '-webkit-appearance': 'none',
                                        appearance: 'textfield',
                                    },
                                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                        '-webkit-appearance': 'none',
                                        margin: 0,
                                    },
                                }}
                                required={true}
                                fullWidth
                                value={Math.abs(openModal?.price) || ''}
                                disabled={true}
                                label={'Цена открытия'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="number"
                                inputProps={{min: 0}}
                            />}
                            {openModal?.variant === 'robot' && <TextField
                                // error={+openModal?.estimated_tp > +openModal?.estimated_tp_old}
                                // helperText={+openModal?.estimated_tp > +openModal?.estimated_tp_old ? `Макс. TP ${openModal?.estimated_tp_old}` : ''}
                                sx={{
                                    '& input[type=number]': {
                                        '-moz-appearance': 'textfield',
                                        '-webkit-appearance': 'none',
                                        appearance: 'textfield',
                                    },
                                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                        '-webkit-appearance': 'none',
                                        margin: 0,
                                    },
                                }}
                                required={true}
                                fullWidth
                                value={Math.abs(openModal?.estimated_tp) || ''}
                                onChange={(e) => setOpenModal({...openModal, estimated_tp: Math.abs(+e.target.value)})}
                                label={'Пропишите TP'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="number"
                                inputProps={{min: 0}}
                            />}

                        </Stack>
                        <Stack justifyContent="flex-end" flexDirection={'row'} gap={'16px'}>
                            <Button
                                disabled={isLoadingMutation}
                                sx={{maxWidth: 268}}
                                color="success"
                                onClick={handleChangeGrid}
                            >Сохранить</Button>
                            <Button
                                disabled={isLoadingMutation}
                                sx={{maxWidth: 268}}
                                color="inherit"
                                onClick={handleCloseModal}
                            >Отменить</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default CorrectionPage;